<!--
 * @Description: App页面，主页
 * @Author: Ronda
 * @Date: 2021-06-29 08:45:48
 * @LastEditors: Ronda
 * @LastEditTime: 2021-07-01 09:25:26
-->
<template>
  <div id="app">
    <!-- 这里将来展示一级路由所对应的页面Login页或者Home页 -->
    <router-view />
  </div>
</template>

<script>
  export default {
    data () {
      return {};
    },
    computed: {},
    methods: {},
    created () { },
    mounted () { },
  };
</script>
<style lang="less">
  html,
  body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
</style>