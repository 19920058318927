import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import less from 'less'
import 'vant/lib/index.less';
import './assets/css/style.less';
import './assets/css/thumb.less';
import 'animate.css';
import { Toast, Notify, Swipe, SwipeItem, Col, Row, Dialog } from 'vant';
import 'amfe-flexible';

Vue.use(Swipe);
Vue.use(Col);
Vue.use(Row);
Vue.use(SwipeItem);
Vue.use(Toast);
Vue.use(Notify);
Vue.use(Dialog)
Vue.use(Vant);
Vue.use(less)
Vue.config.productionTip = false
Vue.prototype.$thumbBaseUrl = 'https://console.fengyechanye.com/prod-api';
Vue.prototype.$toast = Toast;
Vue.prototype.$notify = Notify;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')