import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'Login',
        component: () =>
            import ('@/views/Login'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import ('@/views/Register')
    },
    {
        // path: '/',
        // name: 'Index',
        // redirect: '',
        // component: () => import('@/views/home/Order'),
        path: '/',
        name: 'Home',
        redirect: '/index',
        component: () =>
            import ('@/views/Home'),
        // 子路由
        children: [
            // 首页路由
            {
                path: 'index',
                name: 'Index',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/home/Index'),
                meta: {
                    title: '首页',
                    toJumpUrl: false,
                    back: false
                }
            },
            // 政策
            {
                path: 'policy',
                name: 'Policy',
                component: () =>
                    import ('@/views/policy'),
                meta: {
                    title: '政策解读',
                    toJumpUrl: false,
                    back: false
                }

            },
            // 政策详情
            {
                path: 'policyDetail',
                name: 'PolicyDetail',
                component: () =>
                    import ('@/views/policy/detail'),
                meta: {
                    title: '政策解读',
                    toJumpUrl: false,
                    back: '/policy'
                }

            },
            // 服务
            {
                path: 'service',
                name: 'Service',
                component: () =>
                    import ('@/views/service'),
                meta: {
                    title: '服务',
                    toJumpUrl: false,
                    back: false
                }
            },
            // 服务详情
            {
                path: 'serviceDetail',
                name: 'ServiceDetail',
                component: () =>
                    import ('@/views/service/detail'),
                meta: {
                    title: '服务',
                    toJumpUrl: false,
                    back: '/service'
                }

            },

            // 个体服务列表
            {
                path: '/individualList',
                name: 'IndividualList',
                component: () =>
                    import ('@/views/individual/list'),
                meta: {
                    title: '个体服务',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 个体服务详情
            {
                path: 'individualDetail',
                name: 'individualDetail',
                component: () =>
                    import ('@/views/individual/detail'),
                meta: {
                    title: '个体服务',
                    toJumpUrl: false,
                    back: '/individualList'
                }
            },
            // 个体服务
            {
                path: 'individual',
                name: 'Individual',
                component: () =>
                    import ('@/views/individual'),
                meta: {
                    title: '个体服务',
                    toJumpUrl: false,
                    back: '/index'
                }
            },
            // 个体服务注册
            {
                path: 'registerService',
                name: 'registerService',
                component: () =>
                    import ('@/views/individual/registerService'),
                meta: {
                    title: '个体服务注册',
                    toJumpUrl: false,
                    back: '/individual'
                }
            },
            // 我的主页
            {
                path: '/mine',
                name: 'Mine',
                component: () =>
                    import ('@/views/mine'),
                meta: {
                    title: '我的',
                    toJumpUrl: false,
                    back: false
                }
            },
            // 消息通知
            {
                path: '/message',
                name: 'Message',
                component: () =>
                    import ('@/views/message'),
                meta: {
                    title: '消息通知',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 消息通知详情
            {
                path: '/messageDetail',
                name: 'MessageDetail',
                component: () =>
                    import ('@/views/message/detail'),
                meta: {
                    title: '消息通知详情',
                    toJumpUrl: false,
                    back: '/message'
                }
            },
            // 订单
            {
                path: '/order',
                name: 'Order',
                component: () =>
                    import ('@/views/order'),
                meta: {
                    title: '我的订单',
                    toJumpUrl: false,
                    back: '/mine'
                }

            },
            // 订单详情
            {
                path: 'order_detail',
                name: 'Order_Detail',
                component: () =>
                    import ('@/views/home/Order_detail'),
                meta: {
                    title: '产品',
                    toJumpUrl: false,
                    back: '/index'
                }
            },
            // 我的企业
            {
                path: '/customer',
                name: 'Customer',
                component: () =>
                    import ('@/views/customer'),
                meta: {
                    title: '我的企业',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 申请发票详情
            {
                path: '/toInvoice',
                name: 'ToInvoice',
                component: () =>
                    import ('@/views/customer/toInvoice'),
                meta: {
                    title: '申请发票',
                    toJumpUrl: false,
                    back: '/customer'
                }
            },
            // 账户管理
            {
                path: '/account',
                name: 'Account',
                component: () =>
                    import ('@/views/mine/Account'),
                meta: {
                    title: '我的账户',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 发票管理
            {
                path: '/invoice',
                name: 'Invoice',
                component: () =>
                    import ('@/views/invoice'),
                meta: {
                    title: '发票管理',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 申请发票详情
            {
                path: '/invoiceDetail',
                name: 'InvoiceDetail',
                component: () =>
                    import ('@/views/invoice/invoiceDetail'),
                meta: {
                    title: '申请发票',
                    toJumpUrl: false,
                    back: '/invoice'
                }
            },
            // 添加发票抬头
            {
                path: '/invoiceAdd',
                name: 'InvoiceAdd',
                component: () =>
                    import ('@/views/invoice/invoiceAdd'),
                meta: {
                    title: '添加发票抬头',
                    toJumpUrl: false,
                    back: '/invoice'
                }
            },
            // 客户列表
            {
                path: '/client',
                name: 'Client',
                component: () =>
                    import ('@/views/home/Client'),
                meta: {
                    title: '客户列表',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 返拥结算
            {
                path: '/discount',
                name: 'Discount',
                component: () =>
                    import ('@/views/discount'),
                meta: {
                    title: '返拥结算',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 返拥结算详情
            {
                path: '/discountDetail',
                name: 'DiscountDetail',
                component: () =>
                    import ('@/views/discount/detail'),
                meta: {
                    title: '返拥结算',
                    toJumpUrl: false,
                    back: '/discount'
                }
            },
            // 扶持结算
            {
                path: '/rebate',
                name: 'Rebate',
                component: () =>
                    import ('@/views/rebate'),
                meta: {
                    title: '扶持结算',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 扶持结算详情
            {
                path: '/rebateDetail',
                name: 'RebateDetail',
                component: () =>
                    import ('@/views/rebate/RebateDetail'),
                meta: {
                    title: '扶持结算',
                    toJumpUrl: false,
                    back: '/rebate'
                }
            },
            // 修改密码
            {
                path: '/forgetPassword',
                name: 'ForgetPassword',
                component: () =>
                    import ('@/views/home/ForgetPassword'),
                meta: {
                    title: '修改密码',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 模块进入地址
            {
                path: '/addressInfoList',
                name: 'AddressInfoList',
                component: () =>
                    import ('@/views/address/addressList'),
                meta: {
                    title: '地址列表',
                    toJumpUrl: false,
                    back: '/toInvoice'
                }
            },
            // 地址
            {
                path: '/addressList',
                name: 'AddressList',
                component: () =>
                    import ('@/views/address/addressList'),
                meta: {
                    title: '地址列表',
                    toJumpUrl: false,
                    back: '/mine'
                }
            },
            // 新增地址
            {
                path: '/addressAdd',
                name: 'AddressAdd',
                component: () =>
                    import ('@/views/address/addressAdd'),
                meta: {
                    title: '新增地址',
                    toJumpUrl: true,
                    back: '/addressList'
                }
            },
            // 编辑地址
            {
                path: '/addressEdit',
                name: 'AddressEdit',
                component: () =>
                    import ('@/views/address/addressEdit'),
                meta: {
                    title: '编辑地址',
                    toJumpUrl: true,
                    back: '/addressList'
                }
            },
        ],
    },
    //实名认证路由
    // {
    //   path: '/authorization',
    //   name: 'Authorization',
    //   component: () => import('@/views/mine/Authorization'),
    //   meta: {
    //     title: '',
    //     back:false
    //   }
    // },
    //   //个人信息路由
    // {
    //   path: '/authentication_details',
    //   name: 'Authentication_details',
    //   component: () => import('@/views/mine/Authentication_details'),
    //   meta: {
    //     title: '',
    //     back:false
    //   }
    // },     
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router